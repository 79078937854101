import { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { motion } from 'framer-motion';

import cx from 'lib/cx';
import style from './FormInput.module.css';

const FormCheckbox = forwardRef(
  (
    { label, name, value, readOnly, disabled, status, onChange, borderNone, themed, className },
    ref
  ) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const canInteract = !['disabled', 'readonly'].includes(state);

    return (
      <label
        className={cx(
          'flex items-start py-2 px-3',
          {
            'cursor-pointer': canInteract,
            'border-none px-0': borderNone && state !== 'disabled',
          },
          style.base,
          canInteract && value && !borderNone ? style.success : style[state],
          className
        )}
      >
        <input
          name={name}
          id={name}
          type="checkbox"
          className="hidden"
          value={value}
          onChange={() => onChange(!value)}
          ref={ref}
          disabled={disabled}
          readOnly={readOnly}
        />
        <span
          className={cx(
            'flex justify-center items-center h-4 w-4 rounded-sm transition-all duration-200 mr-3 mt-0.5 border shrink-0',
            {
              'bg-white': !value,
              [style.statusBorderColor]: !value,
              'border-green-500 bg-green-500': value && !themed,
              'border-theme-primary bg-theme-primary': value && themed,
            }
          )}
        >
          {value && (
            <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} className="block">
              <FontAwesomeIcon icon={faCheck} size="xs" className="text-white" />
            </motion.span>
          )}
        </span>
        <span>{label}</span>
      </label>
    );
  }
);

FormCheckbox.propTypes = {
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOf([true, false, {}]),
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  borderNone: PropTypes.bool,
  themed: PropTypes.bool,
  className: PropTypes.string,
};

FormCheckbox.defaultProps = {
  name: null,
  value: false,
  status: 'default',
  readOnly: false,
  disabled: false,
  borderNone: false,
  themed: false,
  className: '',
};

export default FormCheckbox;
